import './bootstrap';
import '../assets/scss/app.scss';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import React from 'react';
import * as Sentry from '@sentry/react';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'JMS';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup({ el, App, props }) {
    const { env } = import.meta;
    // Sentry.init({
    //   dsn: env.VITE_SENTRY_DSN_PUBLIC,
    //   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    //   // Performance Monitoring
    //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['advance-jms-app.test'],
    //
    //   // Session Replay
    //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    //   environment: env.MODE.toLowerCase(),
    // });

    const root = createRoot(el);

    // eslint-disable-next-line react/jsx-props-no-spreading
    root.render(<App {...props} />);
  },
  progress: {
    color: '#4B5563',
  },
}).then(() => {
  setTimeout(() => {
    document.getElementById('splash-screen')?.remove();
  }, 500);
});
